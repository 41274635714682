@tailwind base;
@tailwind components;
@tailwind utilities;
@import "react-responsive-modal/styles.css";
@import "swiper/css";
@import "swiper/css/effect-fade";
@import "swiper/css/navigation";
@import "swiper/css/pagination";
// @import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
// @import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Dosis&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Arimo&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Epilogue&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Podkova&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Comfortaa&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Cinzel&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Caveat&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Vollkorn&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Rokkitt&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Changa&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Markazi+Text&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Red+Rose&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Rosario&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Heebo&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");
// // ::-webkit-scrollbar {
// // 	background-color: #ededed;
// // 	width: 7px;
// // 	height: 7px;
// // }

// /* scrollbar */
// ::-webkit-scrollbar {
// 	width: 7px;
// 	height: 7px;
// }

// .Lato {
// 	font-family: "Lato"
// }
// .Roboto {
// 	font-family: "Roboto"
// }
// .Playfair_Display {
// 	font-family: "Playfair Display"
// }
// .Open_Sans {
// 	font-family: "Open Sans"
// }
// .Raleway {
// 	font-family: "Raleway"
// }
// .Poppins {
// 	font-family: "Poppins"
// }

// .Montserrat {
// 	font-family: "Montserrat"
// }

// .Oswald {
// 	font-family: "Oswald"
// }

// .Dosis {
// 	font-family: "Dosis"
// }

// .Source_Sans_Pro {
// 	font-family: "Source Sans Pro"
// }

// .Arimo {
// 	font-family: "Arimo"
// }

// .Epilogue {
// 	font-family: "Epilogue"
// }

// .Podkova {
// 	font-family: "Podkova"
// }

// .Comfortaa {
// 	font-family: "Comfortaa"
// }

// .Merriweather_Sans {
// 	font-family: "Merriweather Sans"
// }

// .Cinzel {
// 	font-family: "Cinzel"
// }


// .EB_Garamond {
// 	font-family: "EB Garamond"
// }

// .Caveat {
// 	font-family: "Caveat"
// }

// .Vollkorn {
// 	font-family: "Vollkorn"
// }


// .Rokkitt {
// 	font-family: "Rokkitt"
// }

// .Changa {
// 	font-family: "Changa"
// }

// .Markazi_Text {
// 	font-family: "Markazi Text"
// }


// .Dancing_Script {
// 	font-family: "Dancing Script"
// }

// .Red_Rose {
// 	font-family: "Red Rose"
// }


// .Rosario {
// 	font-family: "Rosario"
// }

// .Lora {
// 	font-family: "Lora"
// }

// .Quicksand {
// 	font-family: "Quicksand"
// }

// .Inter {
// 	font-family: "Inter"
// }

// .Heebo {
// 	font-family: "Heebo"
// }

// .Josefin_Sans {
// 	font-family: "Josefin Sans"
// }




::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 7px #fafafa;
	-webkit-border-radius: 10px;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: transparent;
	-webkit-box-shadow: inset 0 0 7px #5c5c5c;
}

::-webkit-scrollbar-thumb:hover {
	-webkit-box-shadow: inset 0 0 7px #171717;
}

::-webkit-scrollbar-thumb:window-inactive {
	background: transparent;
}

@font-face {
	font-family: NunitoSans-regular;
	src: url(../../public/fonts/NunitoSans-Regular.ttf);
}

@layer base {
	:root {
		--background: #ffffff;
		--foreground: #0a0a0a;

		--card: #ffffff;
		--card-foreground: #0a0a0a;

		--popover: #ffffff;
		--popover-foreground: #323232;

		--primary: #171717;
		--primary-foreground: #fafafa;

		--secondary: #f5f5f5;
		--secondary-foreground: #171717;

		--muted: #f5f5f5;
		--muted-foreground: #737373;

		--accent: #f5f5f5;
		// --accent: #121212;
		--accent-foreground: #171717;

		--destructive: #ef4444;
		--destructive-foreground: #fafafa;

		--border: #dcdfe6;
		--input: #e5e5e5;
		--ring: #0a0a0a;

		--radius: 0.5rem;

		--overlay: rgba(0, 0, 0, 0.5);
	}

	.dark {
		--background: #0a0a0a;
		--foreground: #fafafa;

		--card: #0a0a0a;
		--card-foreground: #fafafa;

		--popover: #0a0a0a;
		--popover-foreground: #fafafa;

		--primary: #fafafa;
		--primary-foreground: #171717;

		--secondary: #262626;
		--secondary-foreground: #fafafa;

		--muted: #262626;
		--muted-foreground: #a3a3a38e;

		--accent: #262626;
		--accent-foreground: #fafafa;

		--destructive: #7f1d1d;
		--destructive-foreground: #fafafa;

		--border: #dcdfe6;
		--input: #dcdfe6;
		--ring: #d4d4d4;
	}
}

html {
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	font-size: 16px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #323232;
	text-align: left;
	background-color: #fff;
}

.hydrated {
  width: 100%;
}


.d-flex {
	display: flex !important;
}

html, body{
height: 100%;
width: 100%;
overflow-y: hidden;
margin:0;
}

[class*="el-col-"] {
	float: left;
	box-sizing: border-box;
}

.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.text-primary {
	color: #4d8eff !important;
}

.text-secondary {
	color: #979797 !important;
}

.el-card__body {
	padding: 20px;
}

.el-card {
	border-radius: 4px;
	border: 1px solid #ebeef5;
	background-color: #fff;
	overflow: hidden;
	color: #323232;
	transition: 0.3s;
}

.el-footer,
.el-main {
	box-sizing: border-box;
}

.el-main {
	display: block;
	flex: 1;
	flex-basis: auto;
	padding: 20px;
}

.el-aside,
.el-main {
	overflow: auto;
}

flex-column {
	flex-direction: column !important;
}

@layer base {
	* {
		@apply border-border;
	}

	body {
		@apply bg-background text-foreground;
	}
}

/* GLOBAL CSS */

h1 {
	font-size: 2rem !important;
}

h2 {
	font-size: 1.5rem !important;
}

h3 {
	font-size: 1.125rem !important;
}

h5 {
	font-size: 0.875rem !important;
}

h6 {
	font-size: 0.75rem !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 0;
	font-weight: 600;
	line-height: 1.2;
}

.el-row {
	position: relative;
	box-sizing: border-box;
}

.el-row--flex {
	display: flex;
}

.el-link {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	position: relative;
	text-decoration: none;
	outline: none;
	cursor: pointer;
	padding: 0;
	font-size: 1rem;
	font-weight: 600;
}

// SIGN UP PAGE
.sign-up-bg {
	.img-fluid {
		max-height: 820px;
	}

	.mobile-view-message {
		line-height: 1.8;
		background-color: #172b4d;
		color: white;
		padding: 15px;
	}

	// .sprite-icons {
	// 	width: 500px;
	// 	height: 500px;
	// }
	.sign-up-form {

		// .el-form-item {
		// 	margin-bottom: 5px;
		// }
		.facebook-logo {
			color: #3a5998;
		}

		.el-divider--horizontal {
			margin-bottom: 10px;
		}

		.el-form {
			font-size: 12px !important;
		}
	}

	.el-input__inner {
		background-color: #f7fafd !important;
	}

	.help-icon.sprite-icons {
		width: 20px;
		height: 20px;
	}

	.vw-90 {
		width: 80%;
		height: 80vh;
	}

	.stripe-error {
		color: #f56c6c;
		font-size: 12px;
	}

	.field {
		background-color: #f7fafd !important;
		font-weight: 300;
		flex: 1;
		outline: none;
		padding-right: 10px;
		padding-left: 10px;
		color: #31325f;
		padding-right: 10px;
		padding-left: 10px;
		cursor: text;
		border: 1px solid #dcdfe6;
		border-radius: 4px;
		font-size: inherit;
	}
}

.el-checkbox {
	color: #323232;
	font-weight: 600;
	font-size: 14px;
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: center;
	white-space: nowrap;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-right: 30px;

	&:last-of-type {
		margin-right: 0;
	}

	&__input {
		outline: none;
		line-height: 1;
		vertical-align: middle;
	}

	&__inner {
		display: inline-block;
		position: relative;
		border: 1px solid #dcdfe6;
		border-radius: 2px;
		box-sizing: border-box;
		width: 14px;
		height: 14px;
		background-color: #fff;
		z-index: 1;
		transition:
			border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
			background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);

		&:after {
			box-sizing: content-box;
			content: "";
			border: 1px solid #fff;
			border-left: 0;
			border-top: 0;
			height: 7px;
			left: 4px;
			position: absolute;
			top: 1px;
			transform: rotate(45deg) scaleY(0);
			width: 3px;
			transition: transform 0.15s ease-in 0.05s;
			transform-origin: center;
		}
	}

	&__label {
		display: inline-block;
		padding-left: 10px;
		line-height: 19px;
	}

	&__original {
		opacity: 0;
		outline: none;
		position: absolute;
		margin: 0;
		width: 0;
		height: 0;
		z-index: -1;
	}
}

.el-checkbox__input.isChecked .el-checkbox__inner:after {
	transform: rotate(45deg) scaleY(1);
}

.el-checkbox__input.isChecked .el-checkbox__inner {
	background-color: #4d8eff;
	border-color: #4d8eff;
}

// SPINNER
.loader {
	margin-right: 1rem;
	display: inline-block;
	width: 18px;
	--b: 8px;
	aspect-ratio: 1;
	border-radius: 50%;
	// padding: 1px;
	background: conic-gradient(#0000 10%, #ffffff) content-box;
	-webkit-mask: repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg),
		radial-gradient(farthest-side, #0000 calc(100% - var(--b) - 1px), #000 calc(100% - var(--b)));
	-webkit-mask-composite: destination-in;
	mask-composite: intersect;
	animation: l4 1s infinite steps(10);
}

@keyframes l4 {
	to {
		transform: rotate(1turn);
	}
}

.tippy-content {
	font-size: 12px;
	/* Adjust this value to your desired font size */
}

@layer components {
	.border-expand {
		position: relative;
		overflow: hidden;
	}

	.border-expand::before {
		content: "";
		position: absolute;
		top: 50%;
		left: 0;
		height: 0;
		width: 4px;
		background-color: #7192ff;
		transform: translateY(-50%);
		transition: height 0.3s ease;
	}

	.border-expand.active {
		padding-left: 0.5rem;
		background-color: #d2e3ff !important;
		color: black !important;
	}

	.border-expand.active::before {
		height: 100%;
	}
}

/* Basic editor styles */
.tiptap {
	>*+* {
		margin-top: 0.75em;
	}

	border-bottom: solid 1px #ccc;
	border-left: solid 1px #ccc;
	border-right: solid 1px #ccc;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;

	padding: 20px;

	ul {
		padding: 0 1rem;
		list-style: disc;
	}

	ol {
		padding: 0 1rem;
		list-style: decimal;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		line-height: 1.1;
	}

	code {
		background-color: rgba(#616161, 0.1);
		color: #616161;
	}

	pre {
		background: #0d0d0d;
		color: #fff;
		font-family: "JetBrainsMono", monospace;
		padding: 0.75rem 1rem;
		border-radius: 0.5rem;

		code {
			color: inherit;
			padding: 0;
			background: none;
			font-size: 0.8rem;
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}

	blockquote {
		padding-left: 1rem;
		border-left: 2px solid rgba(#0d0d0d, 0.1);
	}

	hr {
		border: none;
		border-top: 2px solid rgba(#0d0d0d, 0.1);
		margin: 2rem 0;
	}
}

.image-carousel-container {
	width: 100%;
	//   height: 100%;
}

.mySwiper {
	width: 100%;
	// height: 100%;
}

.swiper-slide img {
	display: block;
	width: 100%;
	// height: 100%;
	object-fit: cover;
}

.szh-accordion__item-btn {
	width: 100%;
}

.ql-align-justify {
  text-align: justify !important;
}
.ql-align-left {
  text-align: left !important;
}
.ql-align-center {
  text-align: center !important;
}
.ql-align-right {
  text-align: right !important;
}
.pagebuilder-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}
.block-action {
  float: right;
  margin-right: 8px;
}
.el-date-editor .el-input__icon {
  margin-top: -4px;
}
.el-tag {
  border-color: transparent !important;
}
.el-tabs__item.custom-tab {
  padding: 15px 5px 15px 5px;
  text-align: center;
  line-height: 26px;
}
.el-tabs__content.custom-tab {
  height: 100%;
  padding: 12px 26px;
  overflow: auto;
}
.el-tabs__item.is-left {
  height: auto;
  text-align: center !important;
}
.col-6.cursor-pointer {
  width: 50%;
  float: left;
}
.el-tabs__item.is-left .text {
  display: block;
  position: relative;
  text-align: center;
  bottom: 0px;
}
.el-tabs--left .el-tabs__item.is-left {
  text-align: center;
}
.el-tabs--left.el-tabs--card .el-tabs__item.is-left {
  text-align: center;
}
.mobile-responsive {
  border: 1px solid #efefef;
  border-radius: 4px;
}
.mobile-responsive span {
  margin: 0px;
  border-right: 1px solid #efefef;
  padding: 10px 15px;
  cursor: pointer;
}
.drag-init .sprite-icons {
  height: 49px !important;
  width: 35px !important;
}
.cursor-pointer .right-box .sprite-icons {
  width: 35px;
  height: 19px;
}
.mobile-responsive span:last-child {
  border-right: none;
}
.cursor-pointer {
  cursor: pointer;
}
.mobile-responsive span.active {
  background: #e7f0f9;
}
.icon {
  display: inline-block;
  vertical-align: middle;
}
.icon--plus > path {
  fill: #979797;
  stroke: #979797;
  stroke-width: 0.1;
  stroke-linejoin: round;
}
.el-tabs--left.el-tabs--card .el-tabs__item.is-left,
.el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active,
.el-tabs--card > .el-tabs__header,
.el-tabs--left.el-tabs--card .el-tabs__nav {
  border-right: none;
  border-top: none;
  border-bottom: none;
  border-left: none;
}
.el-container.pagebuilder-container {
  height: calc(100vh - 100px);
  background: #fbfbfb;
}
.el-container.client-portal-container {
  height: calc(100vh - 130px);
  background: #fbfbfb;
}
.page {
  display: flex;
}
.wrapper {
  height: calc(100vh - 100px);
}
.client-portal-wrapper {
  height: calc(100vh - 130px);
}
.el-tabs--left .el-tabs__nav-wrap.is-left {
  width: 115px;
}
.el-tabs--left .el-tabs__header.is-left {
  border-right: 1px solid #ddd;
  margin-bottom: 0;
  margin-right: 0px;
}
.el-tabs__item.is-left.is-active {
  background: #e7f0f9;
  margin: 0;
}
.el-tabs--left.el-tabs--card .el-tabs__item.is-left:first-child {
  border-right: none;
}
.mx-table.mx-table-date tr {
  background: none;
}
.mx-table td,
.mx-table th {
  padding: 0px 10px;
}
.mx-input {
  height: 50px;
}
.help-center-icons .sprite-icons {
  width: 16px !important;
  height: 16px !important;
}
.save-tick-icon .sprite-icons {
  width: 16px !important;
  height: 16px !important;
}
.collapsible-border {
  background-color: #e7f0f9;
  border-left: 5px solid #3346a2;
}
.collapsed-section {
  background-color: #e7f0f9;
  border-left: 5px solid #3346a2;
}
@keyframes bounce_infinite {
  0%,
  10%,
  26.5%,
  40%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  20%,
  21.5% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -10px, 0);
  }
  35% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -10px, 0);
  }
  42.5% {
    transform: translate3d(0, -2px, 0);
  }
}
@keyframes jello_infinite {
  0%,
  5.55%,
  50% {
    transform: translateZ(0);
  }
  11.1% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  16.65% {
    transform: skewX(3.125deg) skewY(3.125deg);
  }
  22.2% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  27.75% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  33.3% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  38.85% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  44.4% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
@keyframes pulse_infinite {
  0% {
    transform: scaleX(1);
  }
  25% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  50% {
    transform: scaleX(1);
  }
}
@keyframes shake_infinite {
  0%,
  100% {
    transform: translateZ(0);
  }
  5%,
  15%,
  25%,
  35%,
  45% {
    transform: translate3d(-5px, 0, 0);
  }
  10%,
  20%,
  30%,
  40% {
    transform: translate3d(5px, 0, 0);
  }
}
@keyframes tada_infinite {
  0% {
    transform: scaleX(1);
  }
  5%,
  10% {
    transform: scale3d(0.95, 0.95, 0.95) rotate(-1.5deg);
  }
  15%,
  25%,
  35%,
  45% {
    transform: scale3d(1.05, 1.05, 1.05) rotate(1.5deg);
  }
  20%,
  30%,
  40% {
    transform: scale3d(1.05, 1.05, 1.05) rotate(-1.5deg);
  }
  50% {
    transform: scaleX(1);
  }
}
@keyframes wobble_infinite {
  0% {
    transform: translateZ(0);
  }
  7.5% {
    transform: translate3d(-3%, 0, 0) rotate(-5deg);
  }
  15% {
    transform: translate3d(3%, 0, 0) rotate(3deg);
  }
  22.5% {
    transform: translate3d(-3%, 0, 0) rotate(-3deg);
  }
  30% {
    transform: translate3d(2%, 0, 0) rotate(2deg);
  }
  37.5% {
    transform: translate3d(-1%, 0, 0) rotate(-1deg);
  }
  50% {
    transform: translateZ(0);
  }
}
.bounce {
  animation-name: bounce_infinite;
  transform-origin: center bottom;
  animation-duration: 4s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}
.jello {
  animation-name: jello_infinite;
  transform-origin: center;
  animation-duration: 4s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}
.pulse {
  animation-name: pulse_infinite;
  transform-origin: center;
  animation-duration: 4s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}
.shake {
  animation-name: shake_infinite;
  transform-origin: center;
  animation-duration: 4s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}
.tada {
  animation-name: tada_infinite;
  transform-origin: center;
  animation-duration: 4s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}
.wobble {
  animation-name: wobble_infinite;
  animation-duration: 4s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

.page-builder-edit-section .module-header {
  background-color: #323232 !important;
  color: #e0e0e0 !important;
}
.page-builder-edit-section .profile-pic-container {
  position: absolute;
  width: 84px;
  height: 84px;
  overflow: hidden;
  background: #979797;
  border-radius: 50px;
}
.page-builder-edit-section .text-align {
  text-align: center;
}
.page-builder-edit-section span.ml-3.el-tag--medium.el-tag--light {
  color: #979797;
  margin-right: 7px;
}
.page-builder-edit-section .sprite-icons {
  height: 22px;
  width: 22px;
}
.page-builder-edit-section span.right-box i {
  color: #979797;
}
.page-builder-edit-section .right-arraw > path {
  fill: #979797;
  stroke: #979797;
  stroke-width: 0.1;
  stroke-linejoin: round;
}
.page-builder-edit-section .drag-init {
  position: absolute;
  left: -31px;
  cursor: pointer;
  top: 0px;
}
/* .page-builder-edit-section table {
  border-collapse: collapse ;
  border-spacing: 0;
  width: 100%;
}
.page-builder-edit-section th,
.page-builder-edit-section td {
  text-align: left;
  padding: 8px;
}
.page-builder-edit-section tr:nth-child(even) {
  background-color: #f2f2f2;
} */
.page-builder-edit-section .btn-rigth-margin {
  margin-left: 32%;
  margin-top: 13%;
}
.page-builder-edit-section .profile-pic-container-text {
  font-weight: bold;
  position: relative;
  top: 25px;
  left: 25px;
  font-size: 23px;
  color: #fff;
}
.page-builder-edit-section input[type="file"] {
  display: none;
}
.page-builder-edit-section .profile-pic-container img {
  position: relative;
  width: auto;
  height: 85px;
  border-radius: 50px;
}
.page-builder-edit-section .layout-group {
  margin-bottom: 20px;
}
.page-builder-edit-section .layout-group .layout-first {
  position: relative;
  background: #fff;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  border-radius: 2px;
  height: 80px;
}
.page-builder-edit-section .layout-group .layout-second {
  position: relative;
  background: #fff;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  border-radius: 2px;
  height: 141px;
}
.page-builder-edit-section .layout-group .layout-third {
  position: relative;
  background: #fff;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  border-radius: 2px;
  height: 80px;
}
.page-builder-edit-section .layout-main.active {
  background: #e7f0f9;
}
.page-builder-edit-section .layout-group label {
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
}
.page-builder-edit-section .layout-circle-first {
  position: absolute;
  width: 48.57px;
  height: 48.57px;
  left: 20px;
  top: 14px;
  background: #dcdcdc;
  border-radius: 50px;
}
.page-builder-edit-section .layout-circle-text-first {
  font-weight: bold;
  position: relative;
  top: 12px;
  left: 14px;
  font-size: 15px;
  color: #fff;
}
.page-builder-edit-section .layout-title-first {
  left: 82px;
  position: absolute;
  top: 28px;
}
.page-builder-edit-section .layout-box-first {
  position: absolute;
  width: 80px;
  height: 40px;
  right: 20px;
  top: 20px;
  background: #dcdcdc;
}
.page-builder-edit-section .layout-circle-second {
  position: absolute;
  width: 48.57px;
  height: 48.57px;
  left: 38%;
  top: 14px;
  background: #dcdcdc;
  border-radius: 50px;
}
.page-builder-edit-section .layout-circle-text-second {
  font-weight: bold;
  position: relative;
  top: 12px;
  left: 14px;
  font-size: 15px;
  color: #fff;
}
.page-builder-edit-section .layout-title-second {
  left: 38%;
  position: absolute;
  top: 63px;
}
.page-builder-edit-section .layout-box-second {
  position: absolute;
  width: 80px;
  height: 40px;
  top: 91px;
  background: #dcdcdc;
  left: 33%;
}
.page-builder-edit-section .layout-circle-third {
  position: absolute;
  width: 48.57px;
  height: 48.57px;
  right: 20px;
  top: 14px;
  background: #dcdcdc;
  border-radius: 50px;
}
.page-builder-edit-section .layout-circle-text-third {
  font-weight: bold;
  position: relative;
  top: 12px;
  left: 14px;
  font-size: 15px;
  color: #fff;
}
.page-builder-edit-section .layout-title-third {
  right: 105px;
  position: absolute;
  top: 28px;
}
.page-builder-edit-section .layout-box-third {
  position: absolute;
  width: 80px;
  height: 40px;
  left: 20px;
  top: 20px;
  background: #dcdcdc;
}
.page-builder-edit-section .animate.active {
  background-color: #e7f0f9 !important;
}
.page-builder-edit-section .edit-box {
  padding: 11px 0px 11px 0px;
  background: #f2f6fc;
  border: 1px solid #dee2e6;
  box-sizing: border-box;
  border-radius: 7px;
  margin-bottom: 6px;
  position: relative;
}
.page-builder-edit-section .editor-title {
  margin-bottom: 15px;
  margin-top: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #7a7a7a;
}
.page-builder-edit-section .edit-box span.right-box {
  float: right;
  margin-right: 3px;
  cursor: pointer;
}
.page-builder-edit-section .edit-box span.left-box {
  float: left;
  margin-left: 8px;
  margin-right: 8px;
}
.page-builder-edit-section .image-selector {
  border: 1px dashed #9daebf;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  margin-top: 40px;
  height: 100px;
}
.page-builder-edit-section .image-selector-new {
  border: 1px dashed #9daebf;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  margin-top: 40px;
  min-height: 82px;
}
.page-builder-edit-section .group-upload {
  position: absolute;
  display: table-row-group;
  top: 35%;
  left: 12%;
  color: #9daebf;
}
.page-builder-edit-section .button-image img {
  position: relative;
  width: 100%;
  text-align: center;
}
.page-builder-edit-section .button-image {
  display: inline-block;
  margin-bottom: 0;
}
.page-builder-edit-section .remove-image-set {
  background: #e91e63;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  z-index: 1000;
}
.page-builder-edit-section .button_text.layout-main {
  position: relative;
  width: 94%;
  height: 81.38px;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  border-radius: 2px;
  margin-left: 2%;
}
.page-builder-edit-section .button_text.layout-main.active {
  background: #e7f0f9 !important;
}
.page-builder-edit-section .button_text-line {
  position: absolute;
  width: 118.85px;
  height: 10.03px;
  left: 14.07px;
  background: #dcdcdc;
  border-radius: 2px;
  top: 22px;
}
.page-builder-edit-section .button_text-svg-small .sprite-icons {
  height: 70px;
  width: 55px;
}
.page-builder-edit-section .button_text-svg-big .sprite-icons {
  height: 66px;
  width: 73px;
}
.page-builder-edit-section .button_text.layout-main.backgroud {
  background: #dcdcdc;
}
.page-builder-edit-section .drag-init-social {
  position: absolute;
  left: -19px;
  top: 18px;
}
.page-builder-edit-section .edit-box-social {
  padding: 11px 0px 11px 0px;
  background: none;
  border: none;
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 6px;
  position: relative;
}
.page-builder-edit-section #emoji-palette {
  left: 0px !important;
  max-width: 100% !important;
}
.page-builder-edit-section span.left-symbol {
  position: absolute;
  font-size: 19px;
  top: 18px;
  color: #444242;
}
.page-builder-edit-section input.social-input.social-hover:hover {
  border: 2px solid #000;
}
.page-builder-edit-section input.social-input.social-hover:focus {
  border: 2px solid blue !important;
}
.page-builder-edit-section .edit-box span.left-box-social {
  float: left;
  margin-left: 8px;
  margin-right: 0px;
  border: 1px solid #ddd;
  padding: 9px 5px 9px 6px;
}
.page-builder-edit-section .edit-box-social .cursor-pointer .sprite-icons {
  height: 36px;
  width: 31px;
}
.page-builder-edit-section .edit-box-social .left-box-social {
  border: 1px solid #ddd;
  height: 43px;
  padding: 9px 4px 9px 4px;
}
.page-builder-edit-section .edit-box-social .social-input {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #323232;
  display: inline-block;
  font-size: inherit;
  line-height: 40px;
  outline: none;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 85%;
  padding: 13px;
  height: 42px;
  border-radius: 0px;
  margin-left: -5px;
}
.page-builder-edit-section .edit-box-social .drag-init {
  position: absolute;
  left: -24px;
  top: 19px;
}
.page-builder-edit-section .margin-top {
  margin-top: 25px;
}
.page-builder-edit-section .recommended-size {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  top: 14px;
  color: #838383;
}
.page-builder-edit-section span.insta-line-short {
  position: absolute;
  width: 74%;
  height: 35px;
  left: 13%;
  top: 4px;
  background: #dcdcdc;
  border-radius: 2px;
}
.page-builder-edit-section span.insta-line-box-2 {
  position: absolute;
  width: 36%;
  height: 29px;
  left: 13%;
  background: #dcdcdc;
  border-radius: 2px;
}
.page-builder-edit-section span.insta-line-box-2-right {
  position: absolute;
  width: 36%;
  height: 29px;
  left: 50%;
  background: #dcdcdc;
  border-radius: 2px;
}
.page-builder-edit-section span.insta-line-big {
  position: absolute;
  width: 74%;
  height: 35px;
  left: 13%;
  top: 5px;
  background: #dcdcdc;
  border-radius: 2px;
}
.page-builder-edit-section span.insta-line-big-bottom {
  position: absolute;
  width: 74%;
  height: 32px;
  left: 13%;
  top: 42px;
  background: #dcdcdc;
  border-radius: 2px;
}
.page-builder-edit-section span.insta-line-small {
  position: absolute;
  width: 21%;
  height: 27px;
  left: 13%;
  top: 8px;
  background: #dcdcdc;
  border-radius: 2px;
}
.page-builder-edit-section span.insta-line-mini {
  position: absolute;
  width: 18%;
  height: 27px;
  left: 13%;
  top: 8px;
  background: #dcdcdc;
  border-radius: 2px;
}
.page-builder-edit-section .add-more-btn {
  text-align: center;
  padding: 16px 10px 10px 10px;
}
.page-builder-edit-section .insta-btn {
  position: absolute;
  right: 0;
  padding: 0px 11px 0px 11px;
  background: #3a50b4;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  top: 40px;
  z-index: 10000;
  color: #fff;
  cursor: pointer;
}
.page-builder-edit-section .posts-button input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.page-builder-edit-section .posts-button button {
  background: #3a50b4 !important;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  top: 40px;
  color: #fff !important;
  padding: 11px 14px;
}
.page-builder-edit-section .button-size .option-box,
.page-builder-edit-section .button-size .active {
  width: 33%;
  height: 35px;
  background: #fff;
  border: 1px solid #efefef;
  box-sizing: border-box;
}
.page-builder-edit-section .button-size .active {
  background: #e7f0f9;
}
.page-builder-edit-section .button-size .active span {
  padding: 5px;
  border-radius: 50px;
  background: #dcdcdc;
}
.page-builder-edit-section .shortcuts-container {
  display: none;
}
.page-builder-edit-section .smartpage-container .action-sheet-body .form-group,
.page-builder-edit-section
  .smartpage-container
  .action-sheet-body
  .input-group {
  position: inherit;
}
.page-builder-edit-section .edit-box-social textarea {
  height: 90px;
  width: 98.5%;
  border-radius: inherit;
}
.page-builder-edit-section .title-editor-section .ql-toolbar.ql-snow {
  border-radius: 4px 4px 0px 0px;
  border: 1px solid #dcdfe6;
}
.page-builder-edit-section .title-editor-section .ql-container.ql-snow {
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #dcdfe6;
}
.page-builder-edit-section .title-editor-section .ql-editor {
  height: 70px !important;
  min-height: 70px !important;
}
.page-builder-edit-section .title-editor-section .ql-active {
  color: #3346a2 !important;
}
.page-builder-edit-section .description-editor-section .ql-toolbar.ql-snow {
  border-radius: 4px 4px 0px 0px;
  border: 1px solid #dcdfe6;
}
.page-builder-edit-section .description-editor-section .ql-container.ql-snow {
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #dcdfe6;
}
.page-builder-edit-section .description-editor-section .ql-editor {
  height: 150px !important;
  min-height: 150px !important;
}
.page-builder-edit-section .description-editor-section .ql-active {
  color: #3346a2 !important;
}
.page-builder-edit-section .editor-messenger-block .w-90 {
  width: 90% !important;
}
.page-builder-edit-section .editor-messenger-block .w-30 {
  width: 30%;
}
.page-builder-edit-section .editor-messenger-block .w-40 {
  width: 40%;
}
.page-builder-edit-section .editor-messenger-block .w-20 {
  width: 20%;
}
.page-builder-edit-section .editor-messenger-block .w-25 {
  width: 25%;
}
.page-builder-edit-section .editor-messenger-block .drag-init .sprite-icons {
  width: 35px !important;
  height: 49px !important;
}
.page-builder-edit-section .editor-messenger-block .sprite-icons {
  width: 22px !important;
  height: 22px !important;
}
.page-builder-edit-section .messenger-grid-layout-1 {
  display: grid;
  grid-gap: 12px 12px;
  grid-template-columns: auto;
}
.page-builder-edit-section .messenger-grid-layout-2 {
  display: grid;
  grid-gap: 12px 12px;
  grid-template-columns: auto auto;
}
.page-builder-edit-section .messenger-grid-layout-3 {
  display: grid;
  grid-gap: 12px 12px;
  grid-template-columns: auto auto auto auto;
}
.page-builder-edit-section .button-text-group-section .w-48 {
  width: 48%;
}
.page-builder-edit-section .button-text-group-section .img-outline-icon {
  width: 32px;
  height: 32px;
}
.page-builder-edit-section
  .button-text-group-section
  .group-button-layout
  .bg-light {
  background-color: #dcdcdc !important;
}
.page-builder-edit-section
  .button-text-group-section
  .group-button-layout
  .active {
  background: #e7f0f9 !important;
}
.page-builder-edit-section
  .button-text-group-render-section
  .button-group-grid-layout-2 {
  width: 80% !important;
  display: grid;
  grid-gap: 12px 12px;
  grid-template-columns: 49.2% 49.2%;
}
.page-builder-edit-section
  .button-text-group-render-section
  .button-group-grid-layout-2
  button {
  width: 100%;
}
.page-builder-edit-section
  .button-text-group-render-section
  .button-group-grid-layout-3 {
  width: 80% !important;
  display: grid;
  grid-gap: 12px 12px;
  grid-template-columns: 32.2% 32.2% 32.2%;
}
.page-builder-edit-section
  .button-text-group-render-section
  .button-group-grid-layout-3
  button {
  width: 100%;
}
.page-builder-edit-section
  .button-text-group-render-section
  .button-group-grid-layout-4 {
  width: 80% !important;
  display: grid;
  grid-gap: 12px 12px;
  grid-template-columns: 23.7% 23.7% 23.7% 23.7%;
}
.page-builder-edit-section
  .button-text-group-render-section
  .button-group-grid-layout-4
  button {
  width: 100%;
}
.page-builder-edit-section .apperance-group {
  float: left;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
.page-builder-edit-section .apperance-group:nth-of-type(2n) {
  margin-right: 15px;
}
.page-builder-edit-section .apperance-type.active {
  background-color: #e7f0f9;
  border: 2px solid #d7dce1;
  border-radius: 9px;
}
.page-builder-edit-section .apperance-type--card {
  width: 120px;
  height: 200px;
  border-radius: 9px;
  border: 1px solid #ddd;
}
.page-builder-edit-section .paid-feature-tag::before {
  content: "Pro";
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cpath fill='none' d='M0 0h16v16H0z'/%3E%3Cpath fill='%23fff' d='M13 6h-1V4a4 4 0 00-8 0v2H3a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2V8a2 2 0 00-2-2zm-5 6a1 1 0 111-1 1 1 0 01-1 1zM6 6V4a2 2 0 014 0v2z'/%3E%3C/svg%3E");
  background-size: 12px;
  background-position: top 6px right 8px;
  background-repeat: no-repeat;
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
  color: #fff;
  background-color: #394eb4;
  text-transform: uppercase;
  padding: 2px 24px 2px 8px;
  cursor: pointer;
  margin-top: 1px;
  margin-left: -3px;
  position: absolute;
}
.page-builder-edit-section .apperance-type {
  height: 250px;
  padding: 4px 0px 0px 4px;
}
.page-builder-edit-section .theme-icons.sprite-icons {
  width: 90px;
  height: 90px;
}
.page-builder-edit-section .btn-apply {
  float: right;
  margin-right: 35px !important;
  height: 27px;
  padding: 6px 8px 8px 8px;
  font-size: 13px;
}
.page-builder-edit-section .solid-background {
  width: 75px;
  height: 90px;
  background-color: #dcdcdc;
}
.page-builder-edit-section .gradient-background {
  width: 75px;
  height: 90px;
  background: linear-gradient(180deg, #dcdcdc 0%, #f9f9f9 90%);
}
.page-builder-edit-section .image-background {
  width: 75px;
  height: 90px;
  border: 1px dashed #dcdcdc;
}
.page-builder-edit-section .apperance_theme h4.mb-3 {
  margin-left: 6%;
}
.page-builder-edit-section .apperance-element {
  text-align: center;
}
.page-builder-edit-section .apperance-element .vue-gpickr {
  flex-direction: column;
  box-shadow: none;
}
.page-builder-edit-section .apperance-element .vue-gpickr .vc-sketch {
  width: 93%;
}
.page-builder-edit-section
  .apperance-element
  .vue-gpickr
  .vue-gpickr-inner-container {
  padding-left: 10px;
}
.page-builder-edit-section
  .apperance-element
  .vue-gpickr
  .vue-gpickr-inner-container
  .vue-gpickr-preview-container {
  width: 100%;
}
.page-builder-edit-section
  .apperance-element
  .vue-gpickr
  .vue-gpickr-inner-container
  .vue-gpickr-stops-container
  .vue-gpickr-stops-preview-container {
  width: 100%;
}
.page-builder-edit-section .vc-swatches,
.page-builder-edit-section .vc-chrome {
  width: 275px !important;
  height: auto !important;
  overflow-y: initial !important;
}
.page-builder-edit-section #el-main-section-container-inner {
  z-index: -1;
  position: fixed;
  overflow-y: auto;
}
.page-builder-edit-section input.range-slider-init {
  width: 100%;
  margin-top: 10px;
  height: 18px;
  cursor: pointer;
}
.page-builder-edit-section #el-main-section-container-inner:before {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  content: " ";
  display: block;
  background-size: cover;
  overflow-y: auto;
  z-index: -10;
}
.page-builder-edit-section .grid.grid-type--rectangle {
  margin-bottom: 1.5rem;
}
.page-builder-edit-section .grid {
  padding: 0;
  list-style: none;
}
.page-builder-edit-section .grid .grid__item {
  font-size: 14px;
}
.page-builder-edit-section
  .apperance_buttons
  .grid__inner.grid__inner--hardshadow {
  border: 2px solid #c9cacd;
  box-shadow: 4px 4px 0 0 #878c92;
  border-radius: 6px;
}
.page-builder-edit-section
  .apperance_buttons
  .grid__inner.grid__inner--softshadow {
  border: 2px solid #c9cacd;
  box-shadow: 2px 2px 0 0 #878c92;
  border-radius: 6px;
}
.page-builder-edit-section
  .apperance_buttons
  .grid__inner.grid__inner--fillrounded {
  background-color: #c9cacd fa;
  border-radius: 6px;
}
.page-builder-edit-section
  .apperance_buttons
  .grid__inner.grid__inner--outlinerounded {
  border: 2px solid #c9cacd;
  border-radius: 6px;
}
.page-builder-edit-section .grid .grid__item .grid__inner {
  cursor: pointer;
}
.page-builder-edit-section .grid .grid__item .grid__inner {
  display: block;
  position: relative;
}
.page-builder-edit-section .grid.grid-type--rectangle .grid__inner {
  width: 100%;
  height: 35px;
}
.page-builder-edit-section .grid .grid__item .grid__inner .grid__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.page-builder-edit-section .grid.grid-type--rectangle.active {
  background-color: #e7f0f9;
  border: 2px solid #d7dce1;
  border-radius: 9px;
}
.page-builder-edit-section .button-vc-sketch .vc-sketch {
  width: 100%;
  margin-top: 13px;
}
.page-builder-edit-section .fonts {
  width: 46%;
  border: 1px solid #ddd;
  padding: 13px;
  float: left;
  margin: 6px 3px 6px 6px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
}
.page-builder-edit-section .fonts.active {
  background: #e7f0f9;
  cursor: pointer;
}
.page-builder-edit-section .fonts img {
  width: 30px;
  height: 22px;
  margin-left: 37%;
  cursor: pointer;
}
.page-builder-edit-section .fonts label {
  font-size: 13px;
  cursor: pointer;
}
.page-builder-edit-section .input-stack .input-group:first-child {
  margin-top: 0;
}
.page-builder-edit-section .input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
}
.page-builder-edit-section .input-group .input-group-label {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 0;
  min-width: 80px;
  text-align: left;
  line-height: calc(2.7rem + 2px);
  color: #38474f;
  font-size: 0.9rem;
  white-space: nowrap;
}
.page-builder-edit-section .date-time-range-input .input-stack input:disabled {
  background: #f8f9fa;
  color: #000;
  border-radius: 3px 0 0 3px !important;
  width: 100%;
  height: 42px;
  border: 1px solid #ddd;
  padding: 0px 0px 0px 17px;
}
.page-builder-edit-section .input-group .time-picker.meridian {
  width: 100px;
}
.page-builder-edit-section .form-control.time-picker input {
  width: 85px;
  height: 42px;
  border: 1px solid #ddd;
  margin-left: -1px;
  padding: 0px 0px 0px 10px;
}
.page-builder-edit-section .form-control.time-picker input:focus {
  outline: none;
}
.page-builder-edit-section .badge-secondary {
  color: #fff;
  background-color: #868e96;
  position: absolute;
  right: 14px;
  top: 13px;
  cursor: pointer;
  text-transform: uppercase;
}
.page-builder-edit-section .badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
}
.page-builder-edit-section .date-time-range-input .el-select {
  width: 207px;
  margin-left: 18px;
}
.page-builder-edit-section .date-time-range-input .el-form-item__label {
  width: 62px;
}
.page-builder-edit-section .date-picker-block {
  padding: 7px 7px 7px 7px;
  border: 1px solid #ddd;
  border-radius: 6px;
}
.page-builder-edit-section .el-main-section-container {
  width: 100%;
  background: #fff;
  transform: translate(94%, 1%);
  border: 1px solid #ddd;
  border-radius: 4px;
}
.page-builder-edit-section .first-row {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-spacing: 10px;
  top: 0;
}
.page-builder-edit-section span.h6.text-fill-gray.text-top {
  display: flex;
  margin: -7px 0px 11px 29px;
}
.page-builder-edit-section .first-column {
  display: table-cell;
  position: relative;
  vertical-align: middle;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}
.page-builder-edit-section .profile-button {
  max-width: 300px;
  white-space: normal;
  word-wrap: break-word;
}
.page-builder-edit-section .second-row {
  border-spacing: 10px;
  top: 0;
  text-align: center;
}
.page-builder-edit-section .second-column {
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin-bottom: 9px;
}
.page-builder-edit-section .profile-pic-realtime img {
  position: relative;
  width: 85px;
  height: 85px;
  border-radius: 50px;
}
.page-builder-edit-section .brand-title {
  position: absolute;
  left: 100px;
  right: 0;
  bottom: 0;
  top: 30px;
  margin: auto;
}
.page-builder-edit-section .third-row {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-spacing: 10px;
  top: 0;
}
.page-builder-edit-section .third-column {
  display: table-cell;
  position: relative;
  vertical-align: middle;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  text-align: left;
}
.page-builder-edit-section .brand-title-third {
  position: relative;
  left: 0px;
  margin: auto;
  top: 30px;
}
.page-builder-edit-section .brand-font {
  color: #444;
}
.page-builder-edit-section .margin-bottom-60 {
  margin-bottom: 60px;
}
.page-builder-edit-section .margin-bottom-25 {
  margin-bottom: 20px;
}
.page-builder-edit-section .subtitle-row {
  padding: 0px 10px;
  text-align: center;
}
.page-builder-edit-section * {
  box-sizing: border-box;
}
.page-builder-edit-section .column {
  width: 100%;
  padding: 10px;
}
.page-builder-edit-section .col-2 {
  float: left;
}
.page-builder-edit-section .col-4 {
  float: left;
}
.page-builder-edit-section .row:after {
  content: "";
  display: table;
  clear: both;
}
.page-builder-edit-section .button-text-row {
  position: relative;
  background: #fff;
  border: 1px solid #d3dce6;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 0px 6px rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  margin: auto;
  padding: 8px;
}
.page-builder-edit-section .button-text-row__image {
  vertical-align: top;
  width: 40%;
  height: 40%;
}
.page-builder-edit-section .button-text-row__text {
  display: flex;
  justify-content: center;
  right: 2px;
  width: 100%;
  max-width: 55%;
  white-space: normal;
  word-wrap: break-word;
  font-size: 16px;
}
@media (max-width: 620px) {
  .page-builder-edit-section .button-text-row__text {
    width: 100%;
  }
}
.page-builder-edit-section .button-text-row__image-third {
  vertical-align: top;
  width: 100%;
}
.page-builder-edit-section .button-text-row__text-third {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  white-space: normal;
  word-wrap: break-word;
  font-size: 16px;
  background: #fff 9e;
  border-radius: 6px;
}
@media (max-width: 620px) {
  .page-builder-edit-section .button-text-row__text-third {
    width: 100%;
  }
}
.page-builder-edit-section .button-text-row__text-fourth {
  width: 100%;
  position: relative;
  padding: 6px 0px 6px 0px;
  text-align: left;
  font-size: 16px;
}
.page-builder-edit-section .w-80 {
  width: 80%;
}
.page-builder-edit-section .render-social .sprite-icons {
  height: 30px;
  width: 32px;
}
.page-builder-edit-section .insta-column {
  float: left;
  width: 33.33%;
  padding: 0px;
  height: 125px;
  overflow: hidden;
}
.page-builder-edit-section .insta-column-mini {
  float: left;
  width: 25%;
  padding: 0px;
  height: 125px;
  overflow: hidden;
}
.page-builder-edit-section .insta-column-big {
  float: left;
  width: 50%;
  padding: 0px;
  height: 140px;
  overflow: hidden;
}
.page-builder-edit-section .insta-column img,
.page-builder-edit-section .insta-column-big,
.page-builder-edit-section .big-slide {
  border: 2px solid #fff;
}
.page-builder-edit-section .insta-row::after {
  content: "";
  clear: both;
  display: table;
}
.page-builder-edit-section .big-slide {
  margin-bottom: 4px;
}
.page-builder-edit-section .big-slide img {
  height: 250px !important;
}
.page-builder-edit-section .align-items-center .hardshadow .el-button {
  border: none;
  border-radius: 6px;
}
.page-builder-edit-section .align-items-center .hardshadow .el-button:hover {
  box-shadow: none !important;
}
.page-builder-edit-section .align-items-center .softshadow .el-button {
  border: none;
  border-radius: 6px;
}
.page-builder-edit-section .align-items-center .softshadow .el-button:hover {
  box-shadow: none !important;
}
.page-builder-edit-section .align-items-center .fillrounded .el-button {
  border-radius: 6px;
  border: none;
}
.page-builder-edit-section .align-items-center .outlinerounded .el-button {
  border: 2px solid #c9cacd;
  border-radius: 6px;
}
.page-builder-edit-section .youtube_init {
  width: 100%;
  padding: 10px;
  background: #fff;
  border-radius: 12px 12px;
  -webkit-box-shadow: 0 10px 35px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 35px -5px rgba(0, 0, 0, 0.2);
  border-top: 0 none;
  margin-bottom: 13px;
  overflow-x: hidden;
}
.page-builder-edit-section .youtube_init .right_text {
  margin-left: 6px;
  width: 100%;
  white-space: normal;
  font-size: 15px;
  word-wrap: break-word;
  text-align: justify;
  display: flex;
}
.page-builder-edit-section .youtube_init img {
  vertical-align: top;
  width: 230px;
  min-width: 30%;
  max-width: 230px;
  height: auto;
}
.page-builder-edit-section .youtube_bottom_init {
  width: 100%;
  padding: 10px;
  background: #fff;
  margin-bottom: 13px;
  border-radius: 12px 12px;
  -webkit-box-shadow: 0 10px 35px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 35px -5px rgba(0, 0, 0, 0.2);
  border-top: 0 none;
}
.page-builder-edit-section .youtube_bottom_init .right_text {
  justify-content: center;
  right: 2px;
  width: 100%;
  white-space: normal;
  word-wrap: break-word;
  font-size: 15px;
}
.page-builder-edit-section .youtube_bottom_init img {
  width: 100%;
}
.page-builder-edit-section .medium_init {
  width: 100%;
  padding: 10px;
}
.page-builder-edit-section .medium_init .right_text {
  margin-left: 6px;
  width: 100%;
  white-space: normal;
  font-size: 15px;
  text-align: justify;
  display: flex;
}
.page-builder-edit-section .medium_init img {
  vertical-align: top;
  width: 60%;
  height: auto;
  margin-bottom: 15px;
}
.page-builder-edit-section .medium_init img {
  width: 50%;
}
.page-builder-edit-section .medium_init_bottom_init {
  width: 100%;
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 13px;
}
.page-builder-edit-section .medium_init_bottom_init .right_text {
  justify-content: center;
  right: 2px;
  width: 50%;
  white-space: normal;
  word-wrap: break-word;
  font-size: 15px;
}
.page-builder-edit-section .medium_init_bottom_init img {
  width: 100%;
}
.page-builder-edit-section .button-image-init {
  position: relative;
}
.page-builder-edit-section .button-image-init:before {
  margin-left: -30px;
  margin-top: -30px;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background: rgba(0, 0, 0, 0.6);
  border: 2px solid #fff;
  content: "";
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
}
.page-builder-edit-section .button-image-init:after {
  margin-left: -8px;
  margin-top: -12px;
  width: 21px;
  height: 24px;
  border-style: solid;
  border-width: 12px 0 12px 21px;
  border-color: transparent transparent transparent #fff;
  content: "";
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
}
.page-builder-edit-section .hide-block {
  opacity: 0.4;
}
.page-builder-edit-section .hide-show-icon-eye,
.page-builder-edit-section .hide-show-icon-time {
  display: none;
}
.page-builder-edit-section .hide-block.icon-time .hide-show-icon-time,
.page-builder-edit-section .hide-block.icon-eye .hide-show-icon-eye {
  display: block;
  position: absolute;
  right: 5%;
}
.page-builder-edit-section .video-player-render .bg-black {
  background-color: #000;
}
.page-builder-edit-section
  .personalise-link-section
  .avatar-uploader
  .el-upload {
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.page-builder-edit-section
  .personalise-link-section
  .avatar-uploader
  .el-upload:hover {
  border-color: #409eff;
}
.page-builder-edit-section .personalise-link-section .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.page-builder-edit-section .personalise-link-section .avatar {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  display: block;
}
.font-weight-bolder {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.smartphone {
  position: relative;
  width: 24vw;
  height: 616px;
  margin: auto;
  border: 12px black solid;
  border-top-width: 50px;
  border-bottom-width: 60px;
  border-radius: 36px;
}
/* The horizontal line on the top of the device */
.smartphone:before {
  content: "";
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}
/* The circle on the bottom of the device */
.smartphone:after {
  content: "";
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}
/* The screen (or content) of the device */
.smartphone .content {
  background: white;
  overflow-y: auto;
  max-height: 500px;
}
@media only screen and (min-width: 960px) {
  /* styles for browsers larger than 960px;
	 */
  .smartphone {
    height: 470px;
  }
  .smartphone .content {
    max-height: 372px;
  }
  .template-title {
    font-size: 15px;
  }
  button.active-btn {
    left: 27%;
  }
}
@media only screen and (min-width: 1440px) {
  /* styles for browsers larger than 1440px;
	 */
  .smartphone {
    height: 490px;
  }
  .smartphone .content {
    max-height: 372px;
  }
  .template-title {
    font-size: 15px;
  }
  button.active-btn {
    left: 34%;
  }
}
@media only screen and (min-width: 1500px) {
  /* styles for browsers larger than 1440px;
	 */
  .smartphone {
    height: 616px;
  }
  .smartphone .content {
    max-height: 534px;
  }
  button.active-btn {
    left: 34%;
  }
}
@media only screen and (min-width: 2000px) {
  /* for sumo sized (mac) screens */
  .smartphone {
    height: 640px;
  }
  .smartphone .content {
    max-height: 534px;
  }
  button.active-btn {
    left: 34%;
  }
}
@media only screen and (device-width: 768px) {
  /* default iPad screens */
  .smartphone {
    height: 360px;
  }
  .smartphone .content {
    max-height: 370px;
  }
  .template-title {
    font-size: 14px;
  }
  button.active-btn {
    left: 27%;
  }
}
/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* For portrait layouts only */
  .smartphone {
    height: 430px;
  }
  .smartphone .content {
    max-height: 370px;
  }
  .template-title {
    font-size: 10px;
  }
  button.active-btn {
    left: 27%;
  }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* For landscape layouts only */
  .smartphone {
    height: 310px;
  }
  .smartphone .content {
    max-height: 370px;
  }
  .template-title {
    font-size: 8px;
  }
  button.active-btn {
    left: 27%;
  }
}
.el-button--primary.is-plain.mobile-viw {
  color: #4d8eff;
  background: white;
  border-color: #b8d2ff;
}
.el-button--primary.is-plain.desktop-viw {
  color: #4d8eff;
  background: white;
  border-color: #b8d2ff;
}
.el-button--primary.is-plain.tablet-viw {
  color: #4d8eff;
  background: white;
  border-color: #b8d2ff;
}
.tablet {
  position: relative;
  width: 37vw;
  height: 616px;
  margin: auto;
  border: 12px black solid;
  border-top-width: 50px;
  border-bottom-width: 60px;
  border-radius: 36px;
}
/* The horizontal line on the top of the device */
.tablet:before {
  content: "";
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}
/* The circle on the bottom of the device */
.tablet:after {
  content: "";
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}
/* The screen (or content) of the device */
.tablet .content {
  width: 768px;
  height: 1024px;
  background: white;
  margin: -1px;
}
/* The desktop with borders */
.desktop {
  position: relative;
  width: 60vw;
  height: 616px;
  margin: auto;
  border-radius: 6px;
  border-style: solid;
  border-color: black;
  border-width: 12px 12px 80px;
  background-color: black;
}
/* The top of the keyboard */
.desktop:before {
  content: "";
  display: block;
  position: absolute;
  width: 250px;
  height: 20px;
  bottom: -50px;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  background: #f1f1f1;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 1;
}
/* The screen (or content) of the device */
.desktop .content {
  width: 1366px;
  height: 800px;
  overflow: hidden;
  border: none;
}





